import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'
import route from '../../../src/common/utils/route'
import { getFullCompany } from '../../../src/common/utils/getCompany'
import { locale } from '../../../src/common/i18n'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingPremium = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`${'banners'}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`${'banners'}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Neumáticos Premium" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          En <strong>{getFullCompany()}</strong> somos profesionales dedicados
          al mundo del neumático y el mantenimiento del automóvil con más de{' '}
          <strong>30 años</strong> de experiencia en el sector. Gracias al
          conocimiento que hemos adquirido a lo largo de los años, trabajamos
          con las más prestigiosas marcas de neumáticos del mercado pudiendo
          ofrecer{' '}
          <strong>
            los mejores precios en las Islas Canarias con una calidad de
            servicio excelente
          </strong>
          .
        </p>

        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />

        <section className={styles.section}>
          <h2>Gran selección de Neumáticos Premium</h2>
          <p>
            En nuestra selección de <strong>Marcas Premium</strong> encontrarás{' '}
            <strong>las mejores y más reconocidas marcas de neumáticos</strong>.
            Son las marcas por las que apuestan los{' '}
            <strong>conductores más exigentes</strong> que hacen un uso del
            coche de forma continua. También son la mejor elección para aquellos
            conductores que buscan una <strong>calidad excelente</strong> y un{' '}
            <strong>alto rendimiento</strong> del neumático.
          </p>
        </section>
        <section className={styles.section}>
          <p>
            Estos fabricantes son reconocidos por someter sus modelos de
            neumáticos a múltiples pruebas con el fin de demostrar su liderazgo
            y diferenciación dentro del sector. Ofrecen las mejores prestaciones
            en cuanto a durabilidad, agarre y estabilidad en toda clase de
            carreteras y situaciones climatológicas.
          </p>
        </section>
        <section className={styles.section}>
          <p>
            Las marcas premium destacan por ofrecer las mejores prestaciones en
            el etiquetado de control de la Unión Europea. Sus modelos de
            neumático son categorizados siempre con las mejores puntuaciones en
            las pruebas de control, su{' '}
            <strong>
              eficiencia energética se encuentra entre la clasificación A y B
            </strong>
            , su frenada en mojado es incomparable con marcas de calidad
            inferior obteniendo siempre clasificación A (la máxima nota) y su{' '}
            <strong>
              nivel de sonoridad se encuentra por debajo de los 70 dB
            </strong>{' '}
            en todos sus modelos.
          </p>
        </section>
        <section className={styles.section}>
          <p>
            En esta categoría encontrarás marcas de neumáticos de reconocido
            prestigio como son{' '}
            <strong>
              Michelín, Pirelli, Dunlop, Continental, Goodyear, Vredestein y
              Bridgestone
            </strong>
            .
          </p>
        </section>
      </article>
    </div>
  )
}

export default LandingPremium

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}

const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-premium'),
        temporada: { selectable: false, value: 'verano' },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'verano',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={false} origin={route('landing-premium')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)
